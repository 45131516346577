import { 
    TextField,
    DateField,
    ReferenceField,
    FunctionField,
    Labeled,
} from 'react-admin';
import { Box, Typography } from '@material-ui/core';


export const Audit = props => (
    <Box flex={1} ml={{ xs: 0, lg: '1em' }} mt={{ xs: '1em', lg: 0 }} >
        <Typography variant="h6" gutterBottom>
            Audit
        </Typography>
        <Labeled source="version">
            <TextField
                source="version"
                fullWidth
            />
        </Labeled>
        <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                <Labeled source="createdDate" >
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                        <DateField
                            source="createdDate"
                            fullWidth
                        />
                    </Box>
                </Labeled>
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                <Labeled source="createdBy">
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                        <ReferenceField source="createdBy" reference="employees" fullWidth>
                            <FunctionField render={createdBy => `${createdBy.firstName} ${createdBy.lastName}`} />
                        </ReferenceField>
                    </Box>
                </Labeled>
            </Box>
        </Box>
        <Box display={{ xs: 'block', sm: 'flex' }}>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                 <Labeled source="lastModifiedDate">
                    <DateField
                        source="lastModifiedDate"
                        fullWidth
                    />
                </Labeled>
            </Box>
            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                <Labeled source="modifiedBy">
                    <ReferenceField source="modifiedBy" reference="employees" fullWidth>
                        <FunctionField render={modifiedBy => `${modifiedBy.firstName} ${modifiedBy.lastName}`} />
                    </ReferenceField>
                </Labeled>
            </Box>
        </Box>
    </Box>
);