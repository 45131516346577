import * as React from 'react';
import { Fragment, useCallback } from 'react';
import classnames from 'classnames';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CommonCreateEditInDrawer from './CommonCreateEditInDrawer';
import { useRefresh } from 'react-admin';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 400,
    },
    drawerPaper: {
        zIndex: 100,
    },
}));

  

const CommonPageWithRoutedDrawer = ({ MainContent, DrawerContentForm, realResource, ...props }) => {
    const classes = useStyles();
    const refresh = useRefresh()
    const isXSmall = useMediaQuery(theme =>
        theme.breakpoints.down('xs')
    );
    const history = useHistory();

    const handleClose = useCallback(() => {
            history.push("/" + props.resource );
    }, [history]);

    React.useEffect(() => {
        refresh()
    }, []);
    

    return (
        <div className={classes.root}>
            <Route path={"/" + props.resource + "/:id/"} >
                {({ match }) => {
                    const isMatch = !!(
                        match &&
                        match.params //&&
                        //match.params.id !== 'create'
                    );

                    return (
                        <Fragment>
                            <div className={classnames(classes.list, { [classes.listWithDrawer]: isMatch, })}>
                                <MainContent 
                                    {...props} 
                                    selectedRow={ isMatch ? parseInt((match).params.id, 10) :  undefined  } 
                                    realResource={realResource}
                                />
                            </div>
                            <Drawer
                                variant="persistent"
                                open={isMatch}
                                anchor="right"
                                onClose={handleClose}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                                {isMatch ? (
                                    <CommonCreateEditInDrawer
                                        id={(match).params.id}
                                        onCancel={handleClose}
                                        Form={DrawerContentForm}
                                        realResource={realResource}
                                        {...props}
                                    />
                                ) : null}
                            </Drawer>
                        </Fragment>
                    );
                }}
            </Route>
        </div>
    );

}

export default CommonPageWithRoutedDrawer