import * as React from 'react';
import { Drawer, useMediaQuery } from '@material-ui/core';
import AssetModelListDesktop from './AssetModelListDesktop';
import assetModelFilters from './assetModelFilters';
import AssetModelListMobile from './AssetModelListMobile';
import { List } from 'react-admin';


const AssetModelList = ({selectedRow, realResource, ...props}) => {
    const isXSmall = useMediaQuery(theme =>
        theme.breakpoints.down('xs')
    );

    return (
        <List {...props} 
            bulkActionButtons={true}
            filters={assetModelFilters}
            perPage={25}
            sort={{ field: 'id', order: 'ASC' }}
        >
            {isXSmall ? (
                <AssetModelListMobile selectedRow={ selectedRow } />
            ) : ( 
                <AssetModelListDesktop selectedRow={ selectedRow } />
            )}
        </List>
    );
}
export default AssetModelList;