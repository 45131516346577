import * as React from "react";
import { 
    SelectInput,
    TextInput,
    FunctionField,
    Toolbar,
    FormWithRedirect,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { makeStyles, Box, Card, CardContent, Typography, Drawer } from '@material-ui/core';
import assetModelTypeChoices from './assetModelTypeChoices'
import { Audit } from "../common/Audit";
import SanitizedBoxInToolbar from "../common/SanitizedBoxInToolbar";
import {useDropzone} from 'react-dropzone'
import { ThemeProvider } from "styled-components";
import { StylesProvider, useTheme } from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    imageCell: {
        'padding-top': '0px',
        'padding-right': '24px',
        'padding-bottom': '0px',
        'padding-left': '16px',
        'text-align': 'center',
    },
}));

// Styles for dropzone
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  const acceptStyle = {
    borderColor: '#00e676'
  };
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const loadPreview = (imageFile) => {
    if ( ! imageFile 
        || Object.keys(imageFile).length === 0
        || Object.getPrototypeOf(imageFile) === Object.prototype) {
        return null;
    } else {
        return URL.createObjectURL(imageFile)
    }
}

const Image = (props) => {
    const theme = useTheme()
    return (
        <FunctionField render={record => {
            return (
                <StylesProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <img style={{height: '100px', 'verticalAlign': 'middle'}} 
                            src={loadPreview(props.imageFile) || `${process.env.REACT_APP_API_URL}/assetModels/${record.id}/image`} 
                            onError={({ currentTarget }) => {
                                //currentTarget.onerror = null;
                                currentTarget.hidden=true;
                            }}
                            onLoad={({ currentTarget }) => {
                                //currentTarget.onerror = null;
                                currentTarget.hidden=false;
                            }}
                            />
                    </ThemeProvider>
                </StylesProvider>
            )
        }
        }/>
    )    
}

const EditableImage = ({imageFile, setImageFile, setImageChanged, ...props}) => {
    // Config for dropzone
    const { getRootProps, getInputProps, 
        isFocused,
        isDragAccept,
        isDragReject } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles[0]) {
                setImageFile(acceptedFiles[0])
                setImageChanged(true)
            }
        },
        maxFiles:1,
    })
    // Styles for dropzone
    const style = React.useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);
      return (
        <div {...getRootProps({style})}>
            <Image {...props} imageFile={imageFile}/>
            <input {...getInputProps()} />
            <p>Drop or click to select</p>
        </div>
      )
}

const AssetModelForm = ({ onCancel, mode, imageFile, setImageFile, ...props }) => {
    const disabled = (mode === 'show')
    const classes = useStyles();
    const [imageChanged, setImageChanged] = React.useState(false)
    return (
        <FormWithRedirect className={classes.form} {...props} render={(formProps) => (
            <form>
                <Card>
                    <CardContent>
                        <EditableImage imageFile={imageFile} setImageFile={setImageFile} setImageChanged={setImageChanged}/>
                        <SelectInput disabled={disabled} source="type" fullWidth choices={assetModelTypeChoices} />
                        <TextInput disabled={disabled} source="brand" fullWidth />
                        <TextInput disabled={disabled} source="model" fullWidth />
                        {(props.record.id) ? <Audit flex={1} /> : null }
                            
                    
                        {!disabled  ? 
                            <Toolbar>
                                <SanitizedBoxInToolbar display="flex" justifyContent="space-between" width="100%">
                                    <SaveButton
                                        saving={formProps.saving}
                                        handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                        submitOnEnter={true}
                                        undoable={props.undoable}
                                        disabled={formProps.pristine && !imageChanged }
                                    />
                                    <DeleteButton record={formProps.record} undoable={props.undoable} />
                                </SanitizedBoxInToolbar>
                            </Toolbar>
                            : 
                            null
                        }
                    </CardContent>
                </Card>
            </form>
        )}
    />);
};

export default AssetModelForm