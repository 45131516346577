import * as React from 'react';
import { Empty, List, ReferenceInput, refreshSaga, SelectInput, SimpleForm, Title, useGetIdentity, useGetList, useGetOne, useQuery, useRefresh } from 'react-admin';
import punchFilters from '../punches/punchFilters';
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    CircularProgress,
    TextField,
    Button,
    IconButton
  } from '@material-ui/core';
  import moment from 'moment'
  import momentDurationFormatSetup from 'moment-duration-format'
  import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
  import ChevronRightIcon from '@material-ui/icons/ChevronRight';
  import { makeStyles } from '@material-ui/core/styles';
  import { useMediaQuery } from '@material-ui/core';
  import Select from '@material-ui/core/Select';
  import MenuItem from '@material-ui/core/MenuItem';
  import InputLabel from '@material-ui/core/InputLabel';
  
  moment.updateLocale('en', {
    week: {
      dow : 1, // Monday is the first day of the week.
      doy : moment.localeData('en').firstDayOfYear()
    }
  });

  const useStyles = makeStyles({
    customTable: {
      "& .MuiTableCell-sizeSmall": {
        padding: "6px 0px 6px 10px" // <-- arbitrary value
      }
    },
  });

  
  

const MyPunchesByMonthPage = (props) => {
    const { isMyResource } = props
    const [employeeRef, setEmployeeRef] = React.useState(props.employeeRef);
    
    const classes = useStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
    const refresh = useRefresh()
    //const { identity, loading: identityLoading } = useGetIdentity();
    const [monthMoment, setMonthMoment] = React.useState(moment());
    const [punches, setPunches] = React.useState({});

    

    const EmployeeSelector = () => {
        const { data, isLoading} = useGetList('employees');
        return (
            <>
                <SimpleForm  toolbar={false} component={Box} margin='none'>
                    <SelectInput
                        name='Employee'
                        label='Employee'
                        source='id'
                        initialValue={employeeRef}
                        optionValue='id' 
                        optionText={option => `${option.lastName}, ${option.firstName}`} 
                        onChange={event => setEmployeeRef(event.target.value)}
                        choices={Object.values(data)} 
                        FormHelperTextProps={false}
                    />
                </SimpleForm>
            </>
        )
      }

    const previousMonth = (event) => {
        var newMonthMoment = monthMoment.clone().subtract(1, "months");
        setMonthMoment(newMonthMoment);
    }

    const nextMonth = (event) => {
        var newMonthMoment = monthMoment.clone().add(1, "months");
        setMonthMoment(newMonthMoment);
    }

    const getThisMonthDays = () => {
        var daysInMonth = monthMoment.daysInMonth();
        var arrDays = [];
        for (var i = 1; i <= daysInMonth; i++) {
            arrDays.push( monthMoment.clone().date(i));
        }
        return arrDays;
    }

    const { data, loading, error, refetch } = useQuery(
        { 
            type: 'getList',
            resource: 'punches',
            payload: {  
                pagination: { page: 0 , perPage: 10000 }, 
                sort: { field: 'timestamp', order: 'ASC' }, 
                filter:{ 
                    employeeRef: (employeeRef ? employeeRef : null), 
                    since: monthMoment.startOf("month").toDate(), 
                    until: monthMoment.endOf("month").toDate(),
                }, 
            },
        },
        {enabled: employeeRef ? true : false}
    );

    const MonthTable = props => {
        const space = isMyResource ? '200px' : '254px'
        return (
            <Card>
                <Box sx={{ minWidth: 400 }} style={{ overflow: 'auto', height: 'calc(100vh - ' + space +')'  }}>
                    <Table classes={{root: classes.customTable}} size={isSmall ? "small" : ""}>
                        <TableHead >
                            <TableRow style={{position: 'sticky', top: 0, background: 'white'}}>
                                <TableCell>
                                #
                                </TableCell>
                                <TableCell>
                                In
                                </TableCell>
                                <TableCell>
                                Out
                                </TableCell>
                                <TableCell>
                                Time
                                </TableCell>
                                <TableCell>
                                Day
                                </TableCell>
                                <TableCell>
                                Week
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody style={{ }}>
                            {getDataRows()}
                            {getTotalRow()}
                        </TableBody>
                    </Table>
                </Box>
            </Card>
        )
    }

    /*React.useEffect(() => {
        refresh()
    }, [])*/

    React.useEffect(() => {
        const thesePunches = {}
        if (!data) {
            setPunches(thesePunches)
            return
        }
        var monthTotalTime = moment.duration(0)
        var punchIterator = 0
        var previousPunch = {
            type: 'START', 
            timestamp: monthMoment.startOf("month").toDate()
        }
        getThisMonthDays().map((aDay) => {
            var thisWeek = thesePunches['week_' + aDay.week()]
            if ( ! thisWeek) {
                thesePunches['week_' + aDay.week()] = {
                    addWeekRowspan: 0,
                    weekTotalTime: moment.duration(0),
                    days: {}
                }
                thisWeek = thesePunches['week_' + aDay.week()]
            }
            var thisDay = thisWeek.days['day_' + aDay.date()]
            if ( ! thisDay) {
                thisWeek.days['day_' + aDay.date()] = {
                    addDayRowspan: 0,
                    date: aDay,
                    dayTotalTime: moment.duration(0),
                    punchRows: [{
                        in: null,
                        out: null,
                        totalTime: moment.duration(0)
                    }]
                }
                thisDay = thisWeek.days['day_' + aDay.date()]
            }
            var thisRow = thisDay.punchRows[thisDay.punchRows.length - 1]

            while (punchIterator < data.length) {
                var dataPunch = data[punchIterator]
                var thisPunch = {
                    id: dataPunch.id,
                    timestamp: dataPunch.timestamp,
                    type: dataPunch.type,
                    employeeRef: dataPunch.employeeRef,
                }
                var thisPunchMoment = moment(thisPunch.timestamp)
                if (thisPunchMoment.date() > aDay.date()) {
                    if ( thisPunch.type === 'OUT' && (previousPunch.type === 'START' || previousPunch.type === 'IN') ) {
                        if (thisRow.out) {
                            // There was a previous OUT as last! Do nothing
                        } else {
                            if (thisRow.in) {
                                // Add from previous IN punch to end of day
                                var thisDuration = moment.duration(aDay.clone().endOf('day').diff(moment(thisRow.in.timestamp)))
                                thisRow.out = {type:'TRANSIENT', timestamp: aDay.clone().endOf('day').toDate()}
                                thisRow.totalTime = thisDuration
                                // Increase day, week and month total times
                                if (thisDay.dayTotalTime) thisDay.dayTotalTime = thisDay.dayTotalTime.add(thisDuration)
                                if (thisWeek.weekTotalTime) thisWeek.weekTotalTime = thisWeek.weekTotalTime.add(thisDuration)
                                if (monthTotalTime) monthTotalTime = monthTotalTime.add(thisDuration)
                            } else {
                                // Fill this day with 24 working hours
                                var thisDuration = moment.duration(aDay.clone().endOf('day').diff(aDay.clone().startOf('day')))
                                thisRow.in = {type:'TRANSIENT', timestamp: aDay.clone().startOf('day').toDate()}
                                thisRow.out = {type:'TRANSIENT', timestamp: aDay.clone().endOf('day').toDate()}
                                thisRow.totalTime = thisDuration
                                // Increase day, week and month total times
                                if (thisDay.dayTotalTime) thisDay.dayTotalTime = thisDay.dayTotalTime.add(thisDuration)
                                if (thisWeek.weekTotalTime) thisWeek.weekTotalTime = thisWeek.weekTotalTime.add(thisDuration)
                                if (monthTotalTime) monthTotalTime = monthTotalTime.add(thisDuration)
                            }
                        }
                    }
                    // Go for next day
                    break
                } else if (thisPunchMoment.date() == aDay.date()) {
                    // Set punch info in this day
                    if (thisPunch.type == 'IN') {
                        if (thisRow.in) {
                            thisDay.punchRows.push({})
                            thisWeek.addWeekRowspan = thisWeek.addWeekRowspan + 1
                            thisDay.addDayRowspan = thisDay.addDayRowspan + 1
                            thisRow = thisDay.punchRows[thisDay.punchRows.length - 1]
                        }
                        if (punchIterator + 1 >= data.length) {
                            // This is the last punch registered and it is of type IN
                            thisRow.in = thisPunch
                            thisRow.out = null
                            thisRow.totalTime = null
                            
                        } else {
                            // There are more punches that can be OUT or not....
                            var nextPunch = data[punchIterator + 1]
                            var nextPunchMoment = moment(nextPunch.timestamp)
                            if (nextPunch.type === 'OUT') {
                                // Next punch is OUT
                                if (nextPunchMoment.date() == aDay.date()) {
                                    // Next OUT punch is on same date and will fill OUT and totalTime fields
                                    thisRow.in = thisPunch
                                    thisRow.out = null
                                    thisRow.totalTime = moment.duration(0)
                                    
                                } else {
                                    // Nex OUT punch is a different day, so set OUT at the end of the day
                                    var thisDuration = moment.duration(aDay.clone().endOf('day').diff(moment(thisPunch.timestamp)))
                                    thisRow.in = thisPunch
                                    thisRow.out = {type:'TRANSIENT', timestamp: aDay.clone().endOf('day').toDate()}
                                    thisRow.totalTime = thisDuration
                                    
                                    if (thisDay.dayTotalTime) thisDay.dayTotalTime = thisDay.dayTotalTime.add(thisDuration)
                                    if (thisWeek.weekTotalTime) thisWeek.weekTotalTime = thisWeek.weekTotalTime.add(thisDuration)
                                    if (monthTotalTime) monthTotalTime = monthTotalTime.add(thisDuration)
                                    
                                }
                            } else {
                                // Next punch is also IN, so there is an error. Set totalTimes to null
                                thisRow.in = thisPunch
                                thisRow.out = null
                                thisRow.totalTime = null
                                
                                thisDay.dayTotalTime = null
                                thisWeek.weekTotalTime = null
                                monthTotalTime = null
                            }
                        }
                    } else if (thisPunch.type == 'OUT') {
                        if (thisRow.out) {
                            thisDay.punchRows.push({})
                            thisWeek.addWeekRowspan = thisWeek.addWeekRowspan + 1
                            thisDay.addDayRowspan = thisDay.addDayRowspan + 1
                            thisRow = thisDay.punchRows[thisDay.punchRows.length - 1]
                        }
                        if (thisRow.in) {
                            // Closes a previous IN
                            var thisDuration = moment.duration(moment(thisPunch.timestamp).diff(moment(thisRow.in.timestamp)))
                            thisRow.out = thisPunch
                            thisRow.totalTime = thisDuration
                            
                            if (thisDay.dayTotalTime) thisDay.dayTotalTime = thisDay.dayTotalTime.add(thisDuration)
                            if (thisWeek.weekTotalTime) thisWeek.weekTotalTime = thisWeek.weekTotalTime.add(thisDuration)
                            if (monthTotalTime) monthTotalTime = monthTotalTime.add(thisDuration)
                        } else {
                            if (previousPunch.type === 'IN') {
                                // Prevous IN punch was a previous day
                                var thisDuration = moment.duration(moment(thisPunch.timestamp).diff(aDay.clone().startOf('day')))
                                thisRow.in = {type:'TRANSIENT', timestamp: aDay.clone().startOf('day').toDate()}
                                thisRow.out = thisPunch
                                thisRow.totalTime = thisDuration

                                if (thisDay.dayTotalTime) thisDay.dayTotalTime = thisDay.dayTotalTime.add(thisDuration)
                                if (thisWeek.weekTotalTime) thisWeek.weekTotalTime = thisWeek.weekTotalTime.add(thisDuration)
                                if (monthTotalTime) monthTotalTime = monthTotalTime.add(thisDuration)

                            } else {
                                // There are two consecutive OUTs, so there is an error. Set totalTimes to null
                                thisRow.out = thisPunch
                                thisRow.totalTime = null
                                
                                thisDay.dayTotalTime = null
                                thisWeek.weekTotalTime = null
                                monthTotalTime = null
                            }
                        }
                    }
                    // Set this punch as previous punch for next loop
                    previousPunch = thisPunch
                    // Increment punch iterator
                    punchIterator++
                } else {
                    break
                }
            }
        })

        thesePunches.monthTotalTime = monthTotalTime
        //console.log("thesePunches:")
        //console.log(thesePunches)
        setPunches(thesePunches)
    }, [data])

    const getWekRowspan = (monthDay) => {
        var weekRowspan = 7
        var thisDay = monthDay.clone().date()
        var thisWeek = monthDay.clone().week()
        var thisWeekday = monthDay.clone().weekday()
        var startOfMonth = monthDay.clone().startOf('month')
        var endOfMonth = monthDay.clone().endOf('month')
        if (thisWeek === startOfMonth.week()) {
            weekRowspan = 7 - startOfMonth.weekday()
        } else if (thisWeek === endOfMonth.week()) {
            weekRowspan = endOfMonth.weekday() + 1
        }
        return weekRowspan
    }

    const getDataRows = () => {
        var tableRows = []
        Object.keys(punches).filter(function(keyName) {
            if (keyName.startsWith("week_")) {
                return true
            }
            return false
        }).map((weekName) => {
            
            var thisWeek = punches[weekName]
            
            var weekTotalTime = thisWeek.weekTotalTime
            var rowInWeekCounter = 0
            Object.keys(thisWeek.days).map((dayName) => {
                var thisDay = thisWeek.days[dayName]
                
                //var dayTotalTime = thisDay.dayTotalTime
                var thisDayDate = thisDay.date
                var weekRowspan = getWekRowspan(thisDayDate) + thisWeek.addWeekRowspan
                var dayRowspan = 1 + thisDay.addDayRowspan
                var rowInDayCounter = 0
                thisDay.punchRows.map((punchRow) => {
                    //var inPunch = punchRow.in
                    //var outPunch = punchRow.out
                    //var rowTotalTime = punchRow.totalTime
                    var rowId = 'row_' + thisDayDate.format('dd_DD_MMM') 
                    var rowStyle = {}
                    if (thisDayDate.weekday() > 4) {
                        // weekend style
                        rowStyle.background = '#ece4f2' 
                    }
                    if (rowInWeekCounter == weekRowspan - 1) {
                        rowStyle.borderBottom='2px solid rgb(69, 39, 160)'
                    }
                    var cellStyle = {}
                    if (rowInDayCounter == dayRowspan - 1) {
                        cellStyle.borderBottom='1px solid rgb(69, 39, 160)'
                    }
                    var dayCellStyle = {...cellStyle}
                    var inCellStyle = {...cellStyle}
                    var outCellStyle = {...cellStyle}
                    if (isSmall) {
                        dayCellStyle.maxWidth = 25
                    } else {
                        dayCellStyle.maxWidth = 'none'
                    }
                    if (rowInDayCounter == 0) {
                        dayCellStyle.borderBottom='1px solid rgb(69, 39, 160)'
                    }
                    if (punchRow.in) {
                        rowId = rowId + "_" + punchRow.in.id
                        if (punchRow.in.type === 'TRANSIENT') {
                            inCellStyle.color = 'lightgray'
                        }
                    }
                    if (punchRow.out) {
                        rowId = rowId + "_" + punchRow.out.id
                        if (punchRow.out.type === 'TRANSIENT') {
                            outCellStyle.color = 'lightgray'
                        }
                    }
                    tableRows.push(
                        <TableRow style={ rowStyle } key={rowId} >
                            {rowInDayCounter == 0 ? 
                                <TableCell rowSpan={dayRowspan} style={dayCellStyle}>
                                {thisDayDate.format('dd DD MMM')}
                                </TableCell> 
                            : null}
                            <TableCell style={inCellStyle}>
                            {(punchRow.in) ? moment(punchRow.in.timestamp).format("HH:mm", {trim: false}) : null}
                            </TableCell>
                            <TableCell style={outCellStyle}>
                            {(punchRow.out) ? moment(punchRow.out.timestamp).format("HH:mm", {trim: false}) : null}
                            </TableCell>
                            <TableCell style={cellStyle}>
                            {(punchRow.totalTime) ? punchRow.totalTime.format('HH:mm', {trim: false}) : null}
                            </TableCell>
                            {rowInDayCounter == 0 ? 
                                <TableCell rowSpan={dayRowspan} style={dayCellStyle}>
                                {(thisDay.dayTotalTime) ? thisDay.dayTotalTime.format('HH:mm', {trim: false}) : null}
                                </TableCell> 
                            : null}
                            {rowInWeekCounter == 0 ? 
                                <TableCell rowSpan={weekRowspan} style={cellStyle}>
                                {(weekTotalTime) ? weekTotalTime.format('HH:mm', {trim: false}) : null}
                                </TableCell>
                            : null}
                        </TableRow>
                    )
                    rowInDayCounter++
                    rowInWeekCounter++
                })
            })
        })
        //console.log(tableRows)
        return(tableRows)
    }

    const getTotalRow = () => {
        let rowId = 'row_total'
        return (
            <TableRow key={rowId} >
                <TableCell colSpan={5} style={{textAlign:"right", fontWeight: 'bold'}}>
                MONTH TOTAL
                </TableCell>
                <TableCell style={{fontWeight: 'bold'}}>
                    {(punches.monthTotalTime) ? punches.monthTotalTime.format('HH:mm', {trim: false}) : null}
                </TableCell>
            </TableRow>
        )
    }

    return (
        <div style={{height: '100%'}}>
            <Title title="Punches by month" />
            {/* Month selector */}
            <Box sx={{mt:2}}/>
            <Card>
            <Box>
                
                <Box
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mb: 1,
                    mt: 1
                    }}
                >
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={(e) => previousMonth(e)}
                    >
                        <ChevronLeftIcon/>
                    </Button>
                    <Box sx={{ml: 3, mr: 3}}>
                        <Box>
                            <Typography align="center" >
                                {monthMoment.year()} - {monthMoment.format('MMMM')}
                            </Typography>
                            <Typography align="center">
                                {monthMoment.startOf("month").format("DD/MMM")} - {monthMoment.endOf("month").format("DD/MMM")}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={(e) => nextMonth(e)}
                    >
                        <ChevronRightIcon/>
                    </Button>
                </Box>
                {( ! isMyResource ?
                    <Box
                        sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        mb: 0,
                        mt: 0
                        }}
                    >
                        <EmployeeSelector  /> 
                    </Box>
                : null )}
            </Box>
            </Card>
            <Box sx={{mt:2}}/>
            { (employeeRef) ? <MonthTable/> : null}
            
        </div>
    );
}

export default MyPunchesByMonthPage