const assetModelTypeChoices = [
    { id: 'LAPTOP', name: 'LAPTOP' },
    { id: 'SERVER', name: 'SERVER' },
    { id: 'DESKTOP', name: 'DESKTOP' },
    { id: 'MONITOR', name: 'MONITOR' },
    { id: 'DOCK', name: 'DOCK' },
    { id: 'PHONE', name: 'PHONE' },
    { id: 'PRINTER', name: 'PRINTER' },
]

export default assetModelTypeChoices
