import * as React from 'react';
import { FunctionField, SearchInput, TextInput, ReferenceInput, SelectInput } from 'react-admin';
import assetModelTypeChoices from '../assetModels/assetModelTypeChoices'


const assetModelFilters = (isMyResource) => 
    (isMyResource) ? [
        <SearchInput source="search" alwaysOn />,
        <SelectInput source="type" choices = {assetModelTypeChoices} />,
        <TextInput source="brand" />,
        <TextInput source="model" />,
        <TextInput source="tag" />,
        <TextInput source="serial" />,
        <TextInput source="hostName" />,
        <TextInput source="os" />,
        <TextInput source="cpu" />,
        <TextInput source="ram" />,
        <TextInput source="hdd" />,
    ] : [
        <SearchInput source="search" alwaysOn />,
        <SelectInput source="type" choices = {assetModelTypeChoices} />,
        <TextInput source="brand" />,
        <TextInput source="model" />,
        <TextInput source="tag" />,
        <TextInput source="serial" />,
        <TextInput source="hostName" />,
        <TextInput source="os" />,
        <TextInput source="cpu" />,
        <TextInput source="ram" />,
        <TextInput source="hdd" />,
        <ReferenceInput label="Location" source="locationRef" reference="locations">
            <SelectInput optionValue="id" optionText="name" />
        </ReferenceInput>,
        <ReferenceInput label="Assigned to" source="assignedToRef" reference="employees">
            <SelectInput optionValue="id" optionText={<FunctionField render={assignedToRef => `${assignedToRef.firstName} ${assignedToRef.lastName}`} />}/>
        </ReferenceInput>,
    ]

export default assetModelFilters;