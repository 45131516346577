import * as React from "react";
import { 
    SelectInput,
    TextInput,
    FunctionField,
    Toolbar,
    FormWithRedirect,
    SaveButton,
    DeleteButton,
    SimpleForm,
    DateInput,
    BooleanInput,
    ReferenceInput,
    NumberInput,
    RadioButtonGroupInput,
    required,
    useGetOne,
    usePermissions
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { makeStyles, Box, Card, CardContent, Typography, Drawer } from '@material-ui/core';
import assetModelTypeChoices from '../assetModels/assetModelTypeChoices'
import { Audit } from "../common/Audit";
import SanitizedBoxInToolbar from "../common/SanitizedBoxInToolbar";
import {useDropzone} from 'react-dropzone'
import { ThemeProvider } from "styled-components";
import { StylesProvider, useTheme } from "@material-ui/core/styles";
import { useFormState, useForm, Field } from 'react-final-form';

const useStyles = makeStyles(theme => ({
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    imageCell: {
        'padding-top': '0px',
        'padding-right': '24px',
        'padding-bottom': '0px',
        'padding-left': '16px',
        'text-align': 'center',
    },
}));

// Styles for dropzone
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  const acceptStyle = {
    borderColor: '#00e676'
  };
  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const loadPreview = (imageFile) => {
    if ( ! imageFile 
        || Object.keys(imageFile).length === 0
        || Object.getPrototypeOf(imageFile) === Object.prototype) {
        return null;
    } else {
        return URL.createObjectURL(imageFile)
    }
}

const Image = (props) => {
    const theme = useTheme()
    return (
        <FunctionField render={record => {
            return (
                <StylesProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <img style={{height: '100px', 'verticalAlign': 'middle'}} 
                            src={loadPreview(props.imageFile) || `${process.env.REACT_APP_API_URL}/assetModels/${record.id}/image`} 
                            onError={({ currentTarget }) => {
                                //currentTarget.onerror = null;
                                currentTarget.hidden=true;
                            }}
                            onLoad={({ currentTarget }) => {
                                //currentTarget.onerror = null;
                                currentTarget.hidden=false;
                            }}
                            />
                    </ThemeProvider>
                </StylesProvider>
            )
        }
        }/>
    )    
}

const EditableImage = ({imageFile, setImageFile, setImageChanged, ...props}) => {
    // Config for dropzone
    const { getRootProps, getInputProps, 
        isFocused,
        isDragAccept,
        isDragReject } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles[0]) {
                setImageFile(acceptedFiles[0])
                setImageChanged(true)
            }
        },
        maxFiles:1,
    })
    // Styles for dropzone
    const style = React.useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);
      return (
        <div {...getRootProps({style})}>
            <Image {...props} imageFile={imageFile}/>
            <input {...getInputProps()} />
            <p>Drop or click to select</p>
        </div>
      )
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            {children}
          </Box>
        )}
      </div>
    );
  }

const AssignedToRefInputWithTabs = props => {
    const classes = useStyles();

    //const { values } = useFormState();
    //const { input, meta } = useField('assignedToRef');
    const form = useForm()
    //console.log("form:")
    //console.log(form)
    //console.log(form.getState().values)


    let initialTab = 2
    if (form.getState().values && form.getState().values.assignedToRef && form.getState().values.assignedToRef.type) {
        if (form.getState().values.assignedToRef.type == "Employee") {
            initialTab = 0
        } else if (form.getState().values.assignedToRef.type == "Location") {
            initialTab = 1
        }  
    }

    const [tabValue, setTabValue] = React.useState(initialTab);
   
    const handleTabChange = (event, newValue) => {
        //console.log("getRegisteredFields:")
        //console.log(form.getRegisteredFields())
        
        form.change('assignedToRef.type', null)
        form.change('assignedToRef.id', null)
        if (newValue == 0) {
            form.change('assignedToRef.type', "Employee")
        } else if (newValue == 1) {
            form.change('assignedToRef.type', "Location")
        } 
        setTabValue(newValue);
    };


    return (
        <Box fullWidth className={classes.root}>
            Assigned to:
            <Tabs
                value={tabValue}
                onChange={(event, newValue) => handleTabChange(event, newValue)}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example"
            >
                <Tab style={{minWidth: 10, width:"10"}} icon={<PersonOutlineOutlinedIcon />} label="Employee" aria-label="Employee"/>
                <Tab style={{minWidth: 10, width:"10"}} icon={<LocationOnOutlinedIcon />} label="Location" aria-label="Location" />
                <Tab style={{minWidth: 10, width:"10"}} icon={<CloseOutlinedIcon />} label="None" aria-label="None" />
            </Tabs>
            <TextInput  name="assignedToRef.type" source="assignedToRef.type" type="hidden" label="" style={{display: 'none'}}/>
            <TabPanel value={tabValue} index={0}>
                <ReferenceInput label="Employee" source="assignedToRef.id" reference="employees" >
                    <SelectInput fullWidth optionValue="id" optionText={choice => `${choice.lastName}, ${choice.firstName}`} validate={[required()]}/>
                </ReferenceInput>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ReferenceInput label="Location" source="assignedToRef.id" reference="locations" >
                    <SelectInput fullWidth optionValue="id" optionText="name"  validate={[required()]}/>
                </ReferenceInput>  
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                Asset not assigned
            </TabPanel>
        </Box>

    )
}

const TypedFields = props => {
    const { data, loaded } = useGetOne('assetModels', props.record.assetModelRef);
    if (!loaded) return null
    if (data.type == "LAPTOP" || data.type == "SERVER" || data.type == "DESKTOP") {
        return (
            <>
                <TextInput fullWidth source="os" />
                <TextInput fullWidth source="hostName" />
                <TextInput fullWidth source="cpu" />
                <TextInput fullWidth source="ram" />
                <TextInput fullWidth source="hdd" />
            </>
        )
    } else if (data.type == "MONITOR" ) {
        return (
            <>
                <TextInput fullWidth source="inches" />
            </>
        )
    } else if (data.type == "PHONE" ) {
        return (
            <>
                <TextInput fullWidth source="phoneNumber" />
            </>
        )
    }
    return null
}

const AssetForm = ({ onCancel, mode, imageFile, setImageFile, ...props }) => {
    
    const disabled = (mode === 'show')
    const classes = useStyles();
    const [imageChanged, setImageChanged] = React.useState(false)
    const { permissions, loading: permissionsLoading}  = usePermissions()

    if (!props.record) return null;
    if (permissionsLoading) return null
    return (
        <SimpleForm {...props}>

            <ReferenceInput fullWidth label="Asset model" source="assetModelRef" reference="assetModels">
                <SelectInput fullWidth optionValue="id" optionText={choice => `${choice.type} - ${choice.model}`} validate={[required()]}/>
            </ReferenceInput> 
            <TextInput fullWidth source="tag" />
            <TextInput fullWidth source="serial" />

            <TypedFields/>
            <DateInput fullWidth source="purchaseDate" />

            {(permissions.includes('prambanan_manager')) ?
                <NumberInput fullWidth source="purchaseCost" label="Purchase cost (€)"/>
                :
                null
            }

            <AssignedToRefInputWithTabs/>
            {(props.record.id) ? <Audit /> : null }
        
        </SimpleForm>
    )
}

export default AssetForm