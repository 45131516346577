import * as React from 'react';
import CommonPageWithRoutedDrawer from '../common/CommonPageWithRoutedDrawer';
import PunchList from '../punches/PunchList';
import PunchNow from '../punches/PunchNow';
import { useGetIdentity } from 'react-admin';
import PunchForm from '../punches/PunchForm';

const MainContent = (props) => {
    const { identity, loading: identityLoading } = useGetIdentity();
    if (identityLoading) return (null);
    return (
        <>
            <PunchNow/>
            <PunchList {...props} filter={{ employeeRef: identity.id }} />
        </>
    )
}

const MyPunchesPage = (props) => {
    return (
        <CommonPageWithRoutedDrawer
            MainContent={ MainContent }
            DrawerContentForm={ PunchForm }
            realResource='punches'
            {...props} 
        />
    );
}

export default MyPunchesPage