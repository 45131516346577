import * as React from 'react';
import punchFilters from './punchFilters';
import PunchListDesktop from './PunchListDesktop';
import PunchListMobile from './PunchListMobile';
import { useMediaQuery } from '@material-ui/core';
import { List } from 'react-admin';

const PunchList = ({selectedRow, realResource, ...props}) => {
    const isMyResource = props.resource.startsWith("my")

    const isXSmall = useMediaQuery(theme =>
        theme.breakpoints.down('xs')
    );

    return (
        <List {...props} 
            bulkActionButtons={true}
            filters={punchFilters(isMyResource)}
            perPage={25}
            sort={{ field: 'timestamp', order: 'DESC' }}
            resource={realResource || props.resource}
        >
            {isXSmall ? (
                <PunchListMobile isMyResource={isMyResource} selectedRow={ selectedRow } />
            ) : ( 
                <PunchListDesktop isMyResource={isMyResource} selectedRow={ selectedRow } />
            )}
        </List>
    );

}


export default PunchList