import * as React from "react";
import { useEditController,Edit, Create, Empty, DataProviderContext } from "react-admin";
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import inflection from 'inflection';
import Resizer from "react-image-file-resizer";


const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 40,
        width: 400,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '1em',
    },
}));

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "png",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
  
const transform = async (data, dataProvider, someRef) => {
    
    if (someRef.current)  {
        const image = await resizeFile(someRef.current);
        const tempFileName = await dataProvider.postFile(image)
        return {
            ...data,
            imageFileName: tempFileName.data.tempFileName
        }
    } else {
        return {
                ...data,
            }
    }
}

const CommonCreateEditInDrawer = ({ id, basePath, onCancel, resource, Form, realResource, ...props }) => {
    const isMyResource = resource.startsWith("my")
    const classes = useStyles();

    const dataProvider = React.useContext(DataProviderContext) // This returns the real dataprovider
    const [imageFile, setImageFile] = React.useState()
    const [idLoaded, setIdLoaded] = React.useState()
    const imageFileRef = React.useRef() // Use ref to allow accessing state from external javascript method "transform"
    imageFileRef.current = imageFile

    React.useEffect(() => {
        if (idLoaded != id) {
            setIdLoaded(id)
            setImageFile()
        }
    });

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                {(id === 'create') ? 'New' : ''} {inflection.humanize(inflection.singularize(realResource || resource))} {(id !== 'create') ? '#' + id : ''}
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </div>
            {(id === 'create') ?
                <Create 
                    id={id}
                    onCancel={onCancel}
                    resource={realResource || resource}
                    basePath={basePath}
                    transform={data => transform(data, dataProvider, imageFileRef)}
                    title=" "
                >
                    <Form 
                        {...(isMyResource && { isMyResource: {isMyResource} } )} 
                        imageFile={imageFile} 
                        setImageFile={(data) => setImageFile(data)} 
                    />
                </Create>
            :
                <Edit 
                    id={id}
                    onCancel={onCancel}
                    resource={realResource || resource}
                    basePath={basePath}
                    transform={data => transform(data, dataProvider, imageFileRef)}
                    undoable={false} 
                    title=" "
                >
                    <Form 
                        {...(isMyResource && { isMyResource: {isMyResource} } )} 
                        imageFile={imageFile} 
                        setImageFile={(data) => setImageFile(data)} 
                    />
                </Edit>
            }
        </div>
    );
}

export default CommonCreateEditInDrawer