import * as React from 'react';
import {
    Identifier,
    Datagrid,
    DateField,
    TextField,
    DatagridProps,
    FunctionField,
    ReferenceField,
    NumberField,
    BooleanField,
    usePermissions,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import commonRowStyle from '../common/commonRowStyle';


const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    imageCell: {
        'padding-top': '0px',
        'padding-right': '24px',
        'padding-bottom': '0px',
        'padding-left': '16px',
        //'text-align': 'center',
    },
});

const AssetListDesktop = ({ isMyResource, selectedRow, ...props }) => {
    const classes = useListStyles();
    const { permissions, loading: permissionsLoading}  = usePermissions()
    if (permissionsLoading) return null
    return (
        <Datagrid
            rowClick={(id) => isMyResource ? `/myAssets/${id}/` : 'edit'}
            // @ts-ignore
            rowStyle={commonRowStyle(selectedRow)}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            optimized
        >
            <TextField source="id" />
            <ReferenceField link={false} sortBy="assetModelRef.type" label="Type" source="assetModelRef" reference="assetModels">
                <TextField  source="type" />
            </ReferenceField>
            <FunctionField  cellClassName={classes.imageCell} label="Image" render={record => 
                <img style={{height: '25px', 'verticalAlign': 'middle'}} 
                    src={`${process.env.REACT_APP_API_URL}/assetModels/${record.assetModelRef}/image`} 
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.remove();
                    }} />
            }/>
            <TextField source="tag" />
            <ReferenceField link={false} sortBy="assetModelRef.brand" label="Brand" source="assetModelRef" reference="assetModels">
                <TextField  source="brand" />
            </ReferenceField>
            <ReferenceField link={false} sortBy="assetModelRef.model" label="Model" source="assetModelRef" reference="assetModels">
                <TextField  source="model" />
            </ReferenceField>
            <TextField source="serial" />
            {/*<TextField source="hostName" />
            <TextField source="os" />
            <TextField source="cpu" />
            <TextField source="ram" />
            <TextField source="hdd" />*/}
            <DateField source="purchaseDate" />
            {(permissions.includes('prambanan_manager')) ?
                <NumberField source="purchaseCost" />
                :
                null
            }
            
            {(isMyResource) ? null : 
                /*<ReferenceField sortBy="assignedToRef.firstName" label="Assigned to" source="assignedToRef.id" reference="employees">
                    <FunctionField render={assignedToRef => `${assignedToRef.firstName} ${assignedToRef.lastName}`} />
                </ReferenceField>*/
                <FunctionField  cellClassName={classes.imageCell} label="Assigned to" render={record => 
                    (record.assignedToRef && record.assignedToRef.type && record.assignedToRef.type=="Employee") ?
                        <ReferenceField sortBy="assignedToRef.firstName" label="Assigned to" source="assignedToRef.id" reference="employees">
                            <FunctionField render={assignedToRef => `${assignedToRef.firstName} ${assignedToRef.lastName}`} />
                        </ReferenceField>  
                    : (record.assignedToRef && record.assignedToRef.type && record.assignedToRef.type=="Location") ?
                        <ReferenceField sortBy="assignedToRef.name" label="Assigned to" source="assignedToRef.id" reference="locations">
                            <FunctionField render={assignedToRef => `${assignedToRef.name}`} />
                        </ReferenceField>
                        :
                        null
                    
                }/>
            }
        </Datagrid>
    );
};

export default AssetListDesktop;