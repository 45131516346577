import * as React from "react";
import { 
    List, 
    Datagrid, 
    TextField, 
    EmailField, 
    DateField,
    EditButton,
    Edit,
    ReferenceInput,
    SelectInput,
    TextInput,
    DateInput,
    Create,
    SearchInput,
    ReferenceField,
    Show,
    SimpleList,
    FunctionField, 
    FormWithRedirect,
    required,
    email,
    Toolbar,
    DataProviderContext,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { useMediaQuery, Box, Card, CardContent, Typography } from '@material-ui/core';
import styled, { ThemeProvider } from "styled-components";
import { StylesProvider, useTheme } from "@material-ui/core/styles";

import Avatar from '@material-ui/core/Avatar';
import getInitials from "../../utils/getInitials";
import { Audit } from "../common/Audit";
import {useDropzone} from 'react-dropzone'
import Resizer from "react-image-file-resizer";
import SanitizedBoxInToolbar from "../common/SanitizedBoxInToolbar";

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "png",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });


const employeeFilters = [
    <SearchInput source="search" alwaysOn />,
    <TextInput label="Last name" source="lastName" /*defaultValue="Hello, World!"*/ />,
    <TextInput label="First name" source="firstName" /*defaultValue="Hello, World!"*/ />,
    <TextInput label="Email" source="email" /*defaultValue="Hello, World!"*/ />,
    <ReferenceInput label="Location" source="locationRef" reference="locations">
        <SelectInput optionValue="id" optionText="name" />
    </ReferenceInput>,
];

export const EmployeeList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List {...props} filters={employeeFilters} >
        {
            isSmall ? (
                <SimpleList
                    primaryText={record => record.firstName + ' ' + record.lastName}
                    secondaryText={record => record.email}
                    tertiaryText={record => 
                        <ReferenceField label="Location" source="locationRef" reference="locations">
                            <TextField source="name" />
                        </ReferenceField>
                    }
                    leftAvatar={record =>
                        <FunctionField render={record => 
                            <Avatar
                                sx={{ mr: 2 }}
                                src={`${process.env.REACT_APP_API_URL}/avatar/${record.id}`}
                            >
                                { getInitials(record.firstName + " " + record.lastName) }
                            </Avatar>
                        }/>
                    }
                />
            ) : (
                
                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <FunctionField render={record => 
                        <Avatar
                            sx={{ mr: 2 }}
                            src={`${process.env.REACT_APP_API_URL}/avatar/${record.id}`}
                        >
                            { getInitials(record.firstName + " " + record.lastName) }
                        </Avatar>
                    }/>
                    <TextField source="firstName" />
                    <TextField source="lastName" />
                    <EmailField source="email" />
                    <TextField source="workPhoneNumber" />
                    <DateField source="incorporationDate" />
                    <ReferenceField label="Location" source="locationRef" reference="locations">
                        <TextField source="name" />
                    </ReferenceField>
                    <EditButton />
                </Datagrid>
            )
        }
        </List>
    )
};

const SizedAvatar = styled(Avatar)`
  ${({ size, theme }) => `
    width: ${theme.spacing(size)}px; 
    height: ${theme.spacing(size)}px; 
  `};
`;

const loadPreview = (avatarFile) => {
    if ( ! avatarFile 
        || Object.keys(avatarFile).length === 0
        || Object.getPrototypeOf(avatarFile) === Object.prototype) {
        return null;
    } else {
        return URL.createObjectURL(avatarFile)
    }
}

const EmployeeAvatar = (props) => {
    const theme = useTheme()
    return (
        <FunctionField render={record => 
            <StylesProvider injectFirst>
                <ThemeProvider theme={theme} >
                    <Box style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                        <SizedAvatar
                            size={ 16 }
                            src={loadPreview(props.avatarFile) || `${process.env.REACT_APP_API_URL}/avatar/${record.id}`}
                        >
                            <Typography variant="h2" >{ getInitials(record.firstName + " " + record.lastName) }</Typography>
                        </SizedAvatar>
                    </Box>
                </ThemeProvider>
                
            </StylesProvider>
        }/>
    )    
}

// Styles for dropzone
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  const acceptStyle = {
    borderColor: '#00e676'
  };
  const rejectStyle = {
    borderColor: '#ff1744'
  };
  
const EditableEmployeeAvatar = ({avatarFile, setAvatarFile, onAvatarChange, ...props}) => {
    // Config for dropzone
    const { getRootProps, getInputProps, 
        isFocused,
        isDragAccept,
        isDragReject } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles[0]) {
                setAvatarFile(acceptedFiles[0])
                onAvatarChange()
            }
        },
        maxFiles:1,
    })
    // Styles for dropzone
    const style = React.useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
      ]);
      return (
        <div {...getRootProps({style})}>
            <EmployeeAvatar avatarFile={avatarFile}/>
            <input {...getInputProps()} />
            <p>Drop or click to select</p>
        </div>
      )
}

const EmployeeForm = ({avatarFile, setAvatarFile, mode, ...props}) => {
    const theme = useTheme()
    const [avatarChanged, setAvatarChanged] = React.useState(false)
    const onAvatarChange = () => {
        setAvatarChanged(true)
    }
    return (
        <FormWithRedirect  
        {...props}
        render={formProps => (
            <Card>
                <form>
                    <CardContent>
                        <Box display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={3}>
                                <Typography variant="h6" gutterBottom>
                                    Profile
                                </Typography>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box  mr={{ xs: 0, sm: '0.5em' }} >
                                        {(mode === 'show' ) ? 
                                            <EmployeeAvatar avatarFile={avatarFile}/>
                                            :
                                            <EditableEmployeeAvatar avatarFile={avatarFile} setAvatarFile={setAvatarFile} onAvatarChange={onAvatarChange}/>
                                        }
                                    </Box>
                                    <Box flex={2} >
                                        <Box display={{ xs: 'block', sm: 'flex' }} mr={{ xs: 0, sm: '0.5em' }} >
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                                <TextInput disabled={mode === 'show'} source="firstName" fullWidth />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                                                <TextInput disabled={mode === 'show'} source="lastName" fullWidth />
                                            </Box>
                                        </Box>
                                        <Box display={{ xs: 'block', sm: 'flex' }} mr={{ xs: 0, sm: '0.5em' }} >
                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                                <TextInput disabled={mode === 'show'} type="email" source="email" validate={[email(), required()]} fullWidth
                                                    />
                                            </Box>
                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                                                <TextInput disabled={mode === 'show'} source="workPhoneNumber" fullWidth />
                                            </Box>
                                        </Box>
                                    </Box> 
                                </Box>
                                <Box display={{ xs: 'block', sm: 'flex' }} mr={{ xs: 0, sm: '0.5em' }}>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                        <DateInput disabled={mode === 'show'} source="incorporationDate" fullWidth />
                                    </Box>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }} >
                                        <TextInput disabled={mode === 'show'} source="secondaryEmail" fullWidth />
                                    </Box>
                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }} >
                                        <ReferenceInput label="Location" source="locationRef" reference="locations" fullWidth >
                                            <SelectInput disabled={mode === 'show'} optionValue="id" optionText="name" />
                                        </ReferenceInput>
                                    </Box>
                                </Box>
                            </Box>
                            <Audit flex={1} />
                        </Box>
                    </CardContent>
                    {(mode === 'create' || mode === 'edit') ? 
                        <Toolbar >
                            <SanitizedBoxInToolbar display="flex" justifyContent="space-between" width="100%">
                                <SaveButton
                                    saving={formProps.saving}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                    submitOnEnter={true}
                                    undoable={props.undoable}
                                    disabled={formProps.pristine && !avatarChanged}
                                />
                                <DeleteButton record={formProps.record} undoable={props.undoable} />
                            </SanitizedBoxInToolbar>
                        </Toolbar>
                    : 
                        (null)
                    }
                </form>
            </Card>
        )}/>
    )
}

export const EmployeeShow = props => (
    <Show {...props}>
        <EmployeeForm mode="show"/>
    </Show>
);

const transform = async (data, dataProvider, someRef) => {
    
    if (someRef.current)  {
        const image = await resizeFile(someRef.current);
        const tempFileName = await dataProvider.postFile(image)
        return {
            ...data,
            avatarFileName: tempFileName.data.tempFileName
        }
    } else {
        return {
                ...data,
            }
    }
}

export const EmployeeEdit = props => {
    //const dataProvider = useDataProvider() // This returns a dataproviderwrapper that does not allow to add more parameters to method calls
    const dataProvider = React.useContext(DataProviderContext) // This returns the real dataprovider
    const [avatarFile, setAvatarFile] = React.useState()
    const avatarFileRef = React.useRef() // Use ref to allow accessing state from external javascript method "transform"
    avatarFileRef.current = avatarFile
    
    return (
        <Edit {...props} transform={data => transform(data, dataProvider, avatarFileRef)} undoable={false}>
            <EmployeeForm avatarFile={avatarFile} setAvatarFile={(data) => setAvatarFile(data)}  mode="edit" />
        </Edit>
    )
}

export const EmployeeCreate = props => {
    //const dataProvider = useDataProvider() // This returns a dataproviderwrapper that does not allow to add more parameters to method calls
    const dataProvider = React.useContext(DataProviderContext) // This returns the real dataprovider
    const [avatarFile, setAvatarFile] = React.useState()
    const avatarFileRef = React.useRef() // Use ref to allow accessing state from external javascript method "transform"
    avatarFileRef.current = avatarFile
    
    return (
        <Create {...props} transform={data => transform(data, dataProvider, avatarFileRef)} >
            <EmployeeForm {...props} avatarFile={avatarFile} setAvatarFile={(data) => setAvatarFile(data)}  mode="create"/>
        </Create>
    )
}