import * as React from 'react';
import CommonPageWithRoutedDrawer from '../common/CommonPageWithRoutedDrawer';
import PunchList from '../punches/PunchList';
import PunchNow from '../punches/PunchNow';
import { useGetIdentity } from 'react-admin';
import PunchForm from '../punches/PunchForm';
import PunchesByMonthPage from '../punchesByMonth/PunchesByMonthPage';

const MainContent = (props) => {
    
}

const MyPunchesByMonthPage = (props) => {
    const { identity, loading: identityLoading } = useGetIdentity();
    if (identityLoading) return (null);
    console.log("identity.id: " + identity.id)
    return (
        <>
            <PunchesByMonthPage employeeRef={ identity.id } isMyResource={true} {...props} />
        </>
    )
}

export default MyPunchesByMonthPage