import * as React from 'react';
import CommonPageWithRoutedDrawer from '../common/CommonPageWithRoutedDrawer';
import AssetList from './AssetList';
import AssetForm from './AssetForm';
 
const AssetsPage = (props) => {
    return (
        <CommonPageWithRoutedDrawer
            MainContent={ AssetList }
            DrawerContentForm={ AssetForm }
            {...props} />
    );
}

export default AssetsPage