import * as React from 'react';
import {
    Identifier,
    Datagrid,
    DateField,
    TextField,
    DatagridProps,
    FunctionField,
    ReferenceField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import commonRowStyle from '../common/commonRowStyle';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import StopOutlinedIcon from '@material-ui/icons/StopOutlined';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

const PunchListDesktop = ({ isMyResource, selectedRow, ...props }) => {
    const classes = useListStyles();
    return (
        <Datagrid
            //rowClick="edit"
            rowClick={(id) => isMyResource ? `/myPunches/${id}/` : 'edit'}
            //rowClick={(id) => `/${resource}/${id}/editOnSide`}
            // @ts-ignore
            rowStyle={commonRowStyle(selectedRow)}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            optimized
            hasBulkActions={true}
        >
            <TextField source="id" />
            {/*<TextField source="type" />*/}
            <FunctionField  cellClassName={classes.imageCell} label="Type" render={record => 
                record.type === 'IN' ?
                    <PlayArrowOutlinedIcon />
                :
                    <StopOutlinedIcon />
               
                
            }/>
            <DateField showTime={true} source="timestamp" />
            {(isMyResource) ? '' :
                <ReferenceField sortBy="employeeRef.firstName" label="Employee" source="employeeRef" reference="employees">
                    <FunctionField render={employeeRef => `${employeeRef.firstName} ${employeeRef.lastName}`} />
                </ReferenceField>
            }
        </Datagrid>
    );
};

export default PunchListDesktop;