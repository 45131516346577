import * as React from 'react';
import CommonPageWithRoutedDrawer from '../common/CommonPageWithRoutedDrawer';
import { useGetIdentity } from 'react-admin';
import  AssetList from '../assets/AssetList';
import AssetForm from '../assets/AssetForm';

const MainContent = (props) => {
    const { identity, loading: identityLoading } = useGetIdentity();
    if (identityLoading) return (null);
    return (
        <>
            <AssetList {...props} filter={{ assignedToRef_type:'Employee', assignedToRef_id: identity.id }} />
        </>
    )
}

const MyAssetsPage = (props) => {
    return (
        <CommonPageWithRoutedDrawer
            MainContent={ MainContent }
            DrawerContentForm={ AssetForm }
            realResource='assets'
            {...props} 
        />
    );
}

export default MyAssetsPage