import * as React from 'react';
import { Drawer, useMediaQuery } from '@material-ui/core';
import LocationListDesktop from './LocationListDesktop';
import LocationListMobile from './LocationListMobile';
import { List } from 'react-admin';
import locationFilters from './locationFilters';

const LocationList = ({selectedRow, realResource, ...props}) => {
    const isXSmall = useMediaQuery(theme =>
        theme.breakpoints.down('xs')
    );

    return (
        <List {...props} 
            bulkActionButtons={true}
            filters={locationFilters}
            perPage={25}
            sort={{ field: 'id', order: 'ASC' }}
        >
            {isXSmall ? (
                <LocationListMobile selectedRow={ selectedRow } />
            ) : ( 
                <LocationListDesktop selectedRow={ selectedRow } />
            )}
        </List>
    );
}

export default LocationList