import * as React from 'react';
import { SearchInput, SelectInput, TextInput } from 'react-admin';
import assetModelTypeChoices from './assetModelTypeChoices'


const assetModelFilters = [
    <SearchInput source="search" alwaysOn />,
    <SelectInput source="type" choices = {assetModelTypeChoices} />,
    <TextInput source="brand" />,
    <TextInput source="model" />,
];

export default assetModelFilters;