import * as React from 'react';
import { DateInput, DateTimeInput, FunctionField, ReferenceInput, SearchInput, SelectInput, TextInput } from 'react-admin';

/*
    parse(): input -> record
    format(): record -> input
*/

//const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
const dateParseRegex = /(\d{4})-(\d{2})-(\d{2})/;

const convertDateToString = value => {
    // value is a `Date` object
    if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    const pad = '00';
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(-2)}-${(pad + dd).slice(-2)}`;
};
/*
const dateFormatter = value => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === '') return '';
    if (value instanceof Date) return convertDateToString(value);
    // Valid dates should not be converted
    if (dateFormatRegex.test(value)) return value;

    return convertDateToString(new Date(value));
};
*/
const dateParser = value => {
    console.log("dataParser!!")
    //value is a string of "YYYY-MM-DD" format
    const match = dateParseRegex.exec(value);
    if (match === null) return;
    const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3]);
    if (isNaN(d.getDate())) return;
    return d.toString();
};

const punchFilters = (isMyResource) => (
        isMyResource ? [
            <DateTimeInput source="since" parse={dateParser}/>,
            <DateTimeInput source="until" parse={dateParser}/>,
        ] : [
            //<SearchInput source="search" alwaysOn />,
            <ReferenceInput label="Employee" source="employeeRef" reference="employees">
                <SelectInput optionValue="id" optionText={<FunctionField render={employeeRef => `${employeeRef.firstName} ${employeeRef.lastName}`} />}/>
            </ReferenceInput>,
            <DateInput source="since" parse={dateParser}/>,
            <DateInput source="until" parse={dateParser}/>,
        ]
)

export default punchFilters;