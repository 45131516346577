import * as React from 'react';
import CommonPageWithRoutedDrawer from '../common/CommonPageWithRoutedDrawer';
import AssetModelList from './AssetModelList';
import AssetModelForm from './AssetModelForm';


const AssetModelsPage = (props) => {
    return (
        <CommonPageWithRoutedDrawer
            MainContent={ AssetModelList }
            DrawerContentForm={ AssetModelForm }
            {...props} />
    );
}

export default AssetModelsPage