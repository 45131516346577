import * as React from 'react';
import CommonPageWithRoutedDrawer from '../common/CommonPageWithRoutedDrawer';
import LocationList from './LocationList';
import LocationForm from './LocationForm';


const LocationsPage = (props) => {
    return (
        <CommonPageWithRoutedDrawer
            MainContent={ LocationList }
            DrawerContentForm={ LocationForm }
            {...props} />
    );
}

export default LocationsPage