import * as React from "react";
import { 
    SelectInput,
    SimpleForm,
    DateTimeInput,
    ReferenceInput,
    useGetIdentity
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Audit } from "../common/Audit";

const useStyles = makeStyles(theme => ({
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    imageCell: {
        'padding-top': '0px',
        'padding-right': '24px',
        'padding-bottom': '0px',
        'padding-left': '16px',
        'text-align': 'center',
    },
}));



const PunchForm = ({ onCancel, mode, isMyResource, imageFile, setImageFile, ...props }) => {
    const disabled = (mode === 'show')
    const classes = useStyles();
    const { identity, loading: identityLoading } = useGetIdentity();
    if (identityLoading) return (null);
    if (isMyResource) {
        props.record.employeeRef = identity.id
    }
    return (
        <SimpleForm {...props}  >
            <SelectInput fullWidth source="type" choices={[
                { id: 'IN', name: 'IN' },
                { id: 'OUT', name: 'OUT' }
            ]} />
            <DateTimeInput fullWidth source="timestamp" />
            {isMyResource ? null :
                <ReferenceInput fullWidth label="Employee" source="employeeRef" reference="employees">
                    <SelectInput optionValue="id" optionText={assignedToRef => `${assignedToRef.firstName} ${assignedToRef.lastName}`} />
                </ReferenceInput>
            }
            {(props.record.id) ? <Audit /> : null }
            
        </SimpleForm>
    );
};

export default PunchForm