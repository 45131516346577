import { useKeycloak } from '@react-keycloak/web'
import jwt_decode from 'jwt-decode'
import { useQuery } from 'react-admin';

const useAuthProvider = (clientID) => {
    const { keycloak } = useKeycloak();
    return ({
        login: () => {
            //keycloak.login()
            //console.log('keycloakAuthProvider.js > useAuthProvider > login')
        },
        checkError: () => Promise.resolve(),
        checkAuth: () => {
            //console.log('keycloakAuthProvider.js > useAuthProvider > checkAuth')
            return keycloak.authenticated &&
            keycloak.token ? Promise.resolve() : Promise.reject("Failed to obtain access token.");
        },
        logout: () => {
            keycloak.logout()
            //console.log('keycloakAuthProvider.js > useAuthProvider > logout')
        },
        getIdentity: () => {
            if (keycloak.token) {
                const decoded = jwt_decode(keycloak.token);
                //const id = decoded.sub // keycloak user id
                const keycloakId = decoded.sub
                const fullName = decoded.name
                
                /*const id = keycloak.employeeId
                return Promise.resolve({id, fullName, avatar});
                */
                return fetch(process.env.REACT_APP_API_URL + '/me/', {
                    method: "GET",
                    headers: {"Authorization": "Bearer " + keycloak.token}
                })
                .then(response => response.json())
                .then(json => {
                    const id = json.id
                    const avatar = process.env.REACT_APP_API_URL + '/avatar/' + id
                    return Promise.resolve({id, fullName, avatar});
                }) 
            }
            return Promise.reject("Failed to get identity");
        },
        getPermissions:() => {
            //let hasRole = false;
            if (keycloak.token) {
                const decoded = jwt_decode(keycloak.token);
                //console.log(decoded)
                //decoded.resource_access[clientID].roles.forEach((el) => {
                //decoded.realm_access.roles.forEach((el) => {
                //        if (el === "admin") {
                //        hasRole = true;
                //        return
                //    }
                //});
                return Promise.resolve(decoded.realm_access.roles);
            }
            //if (hasRole) {
            //    return Promise.resolve(true);
            //}
            return Promise.resolve(false);
        },
    });
};

export default useAuthProvider;