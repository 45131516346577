import * as React from 'react';
import CommonPageWithRoutedDrawer from '../common/CommonPageWithRoutedDrawer';
import PunchForm from './PunchForm';
import PunchList from './PunchList';

const PunchesPage = (props) => {
    return (
        <CommonPageWithRoutedDrawer
            MainContent={PunchList}
            DrawerContentForm={ PunchForm }
            {...props} />
    );
}

export default PunchesPage