import * as React from "react";
import { Error, Loading, useGetIdentity, useLoading, useQuery, useUpdateLoading, Button, useRefresh, useDataProvider, useNotify, Link  } from "react-admin";
import { makeStyles, Box, Card, CardContent, Typography, Drawer } from '@material-ui/core';
import moment from 'moment'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { Link as RouterLink } from 'react-router-dom';
import {
    Button as MuiButton,
    Tooltip,
    IconButton,
    useMediaQuery,
    PropTypes as MuiPropTypes,
} from '@material-ui/core';
import classnames from 'classnames';


const useStyles = makeStyles(
    {
        button: {
            display: 'inline-flex',
            alignItems: 'center',
        },
        label: {
            paddingLeft: '0.5em',
        },
        labelRightIcon: {
            paddingRight: '0.5em',
        },
        smallIcon: {
            fontSize: 20,
        },
        mediumIcon: {
            fontSize: 22,
        },
        largeIcon: {
            fontSize: 24,
        },
    },
    { name: 'RaButton' }
);

export const PunchNow = (props) => {
    //const {identity} = props;
    const { identity, loading: identityLoading } = useGetIdentity();
    
    const classes = useStyles(props);
    const [message, setMessage] = React.useState()

    const [lastPunch, setLastPunch] = React.useState()
    const [todayPunches, setTodayPunches] = React.useState()

    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const { data: lastData, loading: lastDataLoading, error: lastDataError, refetch: lastDataRefetch } = useQuery(
        { 
            type: 'getList',
            resource: 'punches',
            payload: { 
                pagination: { page: 0 , perPage: 100 }, 
                sort: { field: 'timestamp', order: 'DESC' }, 
                filter:{ 
                    'employeeRef.id': (identity?identity.id:null),
                } 
            },
        },
        {
            enabled: !identityLoading
        }
    );
    const { data: todayData, loading: todayDataLoading, error: todayDataError, refetch: todayDataRefetch } = useQuery(
        { 
            type: 'getList',
            resource: 'punches',
            payload: { 
                pagination: { page: 0 , perPage: 100 },
                sort: { field: 'timestamp', order: 'ASC' }, 
                filter:{ 
                    'employeeRef.id': (identity?identity.id:null),
                    since: moment(new Date()).startOf('day').toDate(),
                    until: moment(new Date()).endOf('day').toDate()
                } 
            },
        },
        {
            enabled: !identityLoading
        }
    );

    const updateMessage = () => {
        if (lastData && lastData[0]) {
            const lastPunch = lastData[0]
            let message = 'You are ' + lastPunch.type
            message = message + ' since ' + new Date(lastPunch.timestamp).toLocaleString()

            if ( ! todayData || todayData.length == 0) {
                if (lastPunch.type === 'IN') {
                    let workingTime = moment(new Date()) - new moment().startOf('day').toDate()
                    var d = moment.duration(workingTime)
                    var s = Math.floor(d.asHours()) + moment.utc(workingTime).format(":mm:ss");
                    message = message + ". Today's working time: " + s;
                }
            } else if (todayData.length > 0) {
                let since = moment(new Date()).startOf('day')
                //console.log("since: " + since.format("HH:mm:ss"))
                let until
                let workingTime = moment.duration()
                todayData.forEach(punch => {
                    if (punch.type === 'OUT' && since) {
                        //console.log("OUT: " + moment(new Date(punch.timestamp)).format("HH:mm:ss"))
                        let thisWorkingTime = moment(new Date(punch.timestamp)).subtract(since)
                        //console.log("thisWorkingTime: " + thisWorkingTime.format("HH:mm:ss"))
                        workingTime.add(moment.duration(thisWorkingTime))
                        //console.log("OUT workingTime: " + workingTime.format("HH:mm:ss"))
                        since = undefined
                    } else if (punch.type === 'IN') {
                        since =  moment(new Date(punch.timestamp))
                        //console.log("since: " + since.format("HH:mm:ss"))
                    }
                })
                if (since && since <  moment(new Date())) {
                    let thisWorkingTime = moment(new Date()) - since
                    workingTime.add(moment.duration(thisWorkingTime))
                    //console.log("NOW workingTime: " + workingTime.format("HH:mm:ss"))
                }
                //var s = Math.floor(workingTime.asHours()) + moment.utc(workingTime).format(":mm:ss");
                //message = message + ". Today's working time: " + s;
                message = message + ". Today's working time: " + workingTime.format("HH:mm:ss");
            }
            setMessage(message)
        }
    }

    
    React.useEffect(() => {
        if (lastData && lastData[0]) {
            const theLastPunch = lastData[0]
            setLastPunch(theLastPunch)
        }
        if (todayData) {
            setTodayPunches(todayData)
        }
    }, [lastData, todayData]);

    React.useEffect(() => {
        let interval = null
        if (lastPunch) {
            updateMessage()
            if (lastPunch.type === 'IN') {
                interval = setInterval(() => {
                    updateMessage()
                }, 1000);
            }
        }
        return () => clearInterval(interval)
    }, [lastData, todayData]);
    
    

    const handleClick = (punchType) => {
        dataProvider.create('punches', { data: {type: punchType, timestamp: new Date(), employeeRef: identity.id}})
            .then(() => {
                notify('Punch saved');
                refresh()
            })
            .catch((e) => {
                notify('Error: punch not saved', { type: 'warning' })
            });
    }

    if (identityLoading || todayDataLoading || lastDataLoading) return null
    if (lastPunch && lastPunch.type && lastPunch.type === 'IN') {
        return(
            <Card >
                <CardContent style={{ display:'flex', justifyContent:'center' ,flexDirection:'column', alignItems: "center"}}>
                        <Typography variant="h6">
                            {message}    
                        </Typography>
                        <Box display='flex'>
                            <Box sx={{mr:4}}>
                                <MuiButton
                                    className={classnames(classes.button, 'MuiButton-contained', 'MuiButton-containedPrimary')}
                                    size='large'
                                    onClick={() => handleClick('OUT')}
                                >
                                    <StopIcon className={classnames(classes.largeIcon)} />
                                    <span className={classnames({
                                        [classes.label]: true,
                                        [classes.labelRightIcon]: false,
                                    })} style={{ fontSize: '13px' }} >Punch OUT NOW!</span>
                                </MuiButton>
                                {/*<Button variant="contained" className='MuiButton-containedPrimary' label="Punch OUT NOW!" size='large' onClick={() => handleClick('OUT')} >
                                    <StopIcon/>
                                </Button>*/}
                            </Box>
                            <Box>

                                    <MuiButton
                                        className={classnames(classes.button, 'MuiButton-outlinedPrimary')}
                                        size='large'
                                        component={RouterLink}
                                        to={{
                                            pathname: '/myPunches/create',
                                            state: { record: { type: 'OUT' } },
                                        }}
                                    >
                                        <StopIcon className={classnames(classes.largeIcon)} />
                                        <span className={classnames({
                                            [classes.label]: true,
                                            [classes.labelRightIcon]: false,
                                        })} style={{ fontSize: '13px' }} >Punch OUT</span>
                                    </MuiButton>
                                {/*<Button variant="outlined" label="Punch OUT" size='large' 
                                    component={RouterLink}  to={{
                                        pathname: '/myPunches/create',
                                        state: { record: { type: 'OUT' } },
                                        }}   
                                >
                                    <StopIcon/>
                                </Button>*/}
                            </Box>
                        </Box>
                </CardContent>
            </Card>
        )
    } else {
        return (
            <Card>
                <CardContent style={{ display:'flex', justifyContent:'center' ,flexDirection:'column', alignItems: "center"}}>
                    <Typography variant="h6">
                        {message}
                    </Typography>
                    <Box display="flex">
                        <Box sx={{mr:4}}>
                            <MuiButton
                                className={classnames(classes.button, 'MuiButton-contained', 'MuiButton-containedPrimary')}
                                size='large'
                                onClick={() => handleClick('IN')}
                            >
                                <PlayArrowIcon className={classnames(classes.largeIcon)} />
                                <span className={classnames({
                                    [classes.label]: true,
                                    [classes.labelRightIcon]: false,
                                })} style={{ fontSize: '13px' }} >Punch IN NOW!</span>
                            </MuiButton>
                            {/*<Button variant="contained" className='MuiButton-containedPrimary' label="Punch IN NOW!" size='large' onClick={() => handleClick('IN')} >
                                <PlayArrowIcon/>
                            </Button>*/}
                        </Box>
                        <Box>
                            <MuiButton
                                className={classnames(classes.button, 'MuiButton-outlinedPrimary')}
                                size='large'
                                component={RouterLink}
                                to={{
                                    pathname: '/myPunches/create',
                                    state: { record: { type: 'IN' } },
                                }}
                            >
                                <PlayArrowIcon className={classnames(classes.largeIcon)} />
                                <span className={classnames({
                                    [classes.label]: true,
                                    [classes.labelRightIcon]: false,
                                })} style={{ fontSize: '13px' }} >Punch IN</span>
                            </MuiButton>
                            {/*<Button variant="outlined" label="Punch IN" size='large' 
                                component={RouterLink}  to={{
                                        pathname: '/myPunches/create',
                                        state: { record: { type: 'IN' } },
                                        }}   
                            >
                                <PlayArrowIcon/>
                            </Button>*/}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        )
    }

}

export default PunchNow