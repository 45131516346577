import * as React from "react";
import { List, Datagrid, TextField, EmailField, DateField, NumberField, BooleanField, SearchInput, TextInput } from 'react-admin';

const calendarFilters = [
    <SearchInput source="search" alwaysOn />,
    <TextInput source="name" />,
];

export const CalendarList = props => (
    <List {...props} filters={calendarFilters}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <NumberField source="year" />
            {/*<ArrayField source="calendarDays"><SingleFieldList><ChipField source="createdDate" /></SingleFieldList></ArrayField>*/}
            {/*<TextField source="links.self.href" />*/}
        </Datagrid>
    </List>
);

export default CalendarList