import * as React from "react";
import { 
    TextInput,
    SimpleForm,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { Audit } from "../common/Audit";

const useStyles = makeStyles(theme => ({
    form: {
        [theme.breakpoints.up('xs')]: {
            width: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            marginTop: -30,
        },
    },
    imageCell: {
        'padding-top': '0px',
        'padding-right': '24px',
        'padding-bottom': '0px',
        'padding-left': '16px',
        'text-align': 'center',
    },
}));

const LocationForm = ({ onCancel, mode, imageFile, setImageFile, ...props }) => {
    const disabled = (mode === 'show')
    const classes = useStyles();

    return (
        <SimpleForm {...props} >
            <TextInput fullWidth disabled={disabled} source="name" />
            <TextInput fullWidth disabled={disabled} source="address" />
            {(props.record.id) ? <Audit /> : null }
        </SimpleForm>
    );
};

export default LocationForm