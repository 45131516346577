import * as React from 'react';
import { Drawer, useMediaQuery } from '@material-ui/core';
import assetFilters from './assetFilters';
import { List } from 'react-admin';
import AssetListMobile from './AssetListMobile';
import AssetListDesktop from './AssetListDesktop';


const AssetList = ({selectedRow, realResource, ...props}) => {
    const isMyResource = props.resource.startsWith("my")
    const isXSmall = useMediaQuery(theme =>
        theme.breakpoints.down('xs')
    );

    return (
        <List {...props} 
            bulkActionButtons={true}
            filters={assetFilters(isMyResource)}
            perPage={25}
            sort={{ field: 'id', order: 'ASC' }}
            resource={realResource || props.resource}
        >
            {isXSmall ? (
                <AssetListMobile isMyResource={isMyResource} selectedRow={ selectedRow } />
            ) : ( 
                <AssetListDesktop isMyResource={isMyResource} selectedRow={ selectedRow } />
            )}
        </List>
    );
}
export default AssetList;