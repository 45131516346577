import * as React from "react";
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser, fetchUtils, usePermissions } from 'react-admin';

import jsonServerProvider from './utils/BackofficeDataProvider';

import { defaultTheme } from 'react-admin';
import merge from 'lodash/merge';
import indigo from '@material-ui/core/colors/indigo';
import purple from '@material-ui/core/colors/purple';

import assetModels from "./components/assetModels/";
import assets from "./components/assets/";
import myAssets from "./components/myAssets/";
import locations from "./components/locations/";
import { EmployeeList, EmployeeShow, EmployeeEdit, EmployeeCreate } from './components/employees/employees'
import CalendarList from './components/calendars/calendars'
import Keycloak from "keycloak-js";
import useAuthProvider from "./utils/keycloakAuthProvider"
import { ReactKeycloakProvider } from "@react-keycloak/web";
import Dashboard from './layout/dashboard';

import { createBrowserHistory as createHistory } from 'history';
import MyLayout from "./layout/myLayout";
import punches from "./components/punches";
import myPunches from "./components/myPunches";
import myPunchesByMonth from "./components/myPunchesByMonth";
import punchesByMonth from "./components/punchesByMonth";

const myTheme = merge({}, defaultTheme, {
  palette: {
    primary: {
      main: indigo[500]
    },
    secondary: {
      main: purple[800]
    }
  },
  overrides: {
    'MuiAvatar' : {
      root: {
        'background-color': 'white',
      }
    }
  }
});

const initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: "Prambanan",
  clientId: "login-app",
  onLoad: "login-required",
  "checkLoginIframe": false
};

const keycloak = Keycloak(initOptions);

 const onToken = () => {
  if (keycloak.token && keycloak.refreshToken) {
    localStorage.setItem("token", keycloak.token);
    localStorage.setItem("refresh-token", keycloak.refreshToken);
  }
};

const onTokenExpired = () => {
  keycloak
    .updateToken(30)
    .then(() => {
      console.log("onTokenExpired: successfully get a new token", keycloak.token);
    })
    .catch(() => {
      console.error("onTokenExpired: failed to refresh token");
    });
};

// for data provider, it writes token to an authorization header
const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  if (keycloak.token) {
    options.headers.set("Authorization", "Bearer " + keycloak.token);
  } else if (localStorage.getItem("token")) {
    options.headers.set(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
  }
  return fetchUtils.fetchJson(url, options);
};


const httpClient = (url, options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  //const { token } = JSON.parse(localStorage.getItem('token'));
  const  token  = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

//const dataProvider = jsonServerProvider('http://localhost:8080/api', httpClient);
const dataProvider = jsonServerProvider(process.env.REACT_APP_API_URL, fetchJson);
const history = createHistory();

/*const App = () => (
  <Admin history={history} theme={myTheme} dataProvider={dataProvider} loginPage={MyLoginPage}
   //logoutButton={MyLogoutButton} 
   authProvider={ useAuthProvider('login-app')}>
    <Resource name="assets" list={AssetList} edit={AssetEdit} create={AssetCreate}/>
    <Resource name="assetModels" list={AssetmodelList} edit={AssetmodelEdit} create={AssetmodelCreate} />
    <Resource name="locations" list={LocationList} edit={LocationEdit} create={LocationCreate} />
    <Resource name="employees" list={EmployeeList} edit={EmployeeEdit} create={EmployeeCreate} show={EmployeeShow}/>
    <Resource name="punches" list={PunchList} edit={PunchEdit} create={PunchCreate} />
    <Resource name="calendars" list={CalendarList} edit={EditGuesser} />
  </Admin>
);*/
const fetchResources  = (permissions) => {
  return [
      <Resource name="assets" {...assets(permissions)} />,
      <Resource name="myAssets" {...myAssets} />,
      <Resource name="assetModels" {...assetModels(permissions)} />,
      <Resource name="locations" {...locations} />,
      <Resource name="employees" list={EmployeeList} edit={EmployeeEdit} create={EmployeeCreate} show={EmployeeShow}/>,
      <Resource name="punches" {...punches} />,
      <Resource name="myPunches" {...myPunches} />,
      <Resource name="myPunchesByMonth" {...myPunchesByMonth} />,
      <Resource name="punchesByMonth" {...punchesByMonth} />,
      permissions.includes('prambanan_manager') ? <Resource name="calendars" list={CalendarList} edit={EditGuesser} /> : null,
    ]
}

const CustomAdminWithKeycloak = () => {
  console.log('CustomAdminWithKeycloak')
  const customAuthProvider = useAuthProvider(initOptions.clientId);
  return (
    <Admin
      history={history} 
      theme={myTheme} 
      dataProvider={dataProvider}
      authProvider={customAuthProvider}
      loginPage={false}
      title="Prambanan IT Services"
      dashboard={Dashboard}
      layout={MyLayout}
    >
      {fetchResources}
    </Admin>
  );
};

const App = () => {
  console.log("App")
  //const useKeycloak = Cookies.get("USE_KEYCLOAK") === "true";
  //return useKeycloak ? (
  return (  
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<div></div>}
      initOptions={initOptions}
      onTokens={onToken}
      onTokenExpired={onTokenExpired}
      //onEvent={onKeycloakEvent}
    >
      <React.Fragment>
        <CustomAdminWithKeycloak />

      </React.Fragment>
    </ReactKeycloakProvider>
  /*) : (
    <React.Fragment>
      <CustomAdmin />
      <ThemeProvider theme={theme}>
        <Footer />
      </ThemeProvider>
    </React.Fragment>*/
  );
};
export default App;
