import * as React from 'react';
import { useState } from 'react';
import { DashboardMenuItem, Menu, MenuItemLink, getResources, useGetIdentity, Loading, usePermissions } from 'react-admin';
import { useSelector } from 'react-redux'
import DefaultIcon from '@material-ui/icons/ViewList';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import QueryBuilderOutlinedIcon from '@material-ui/icons/QueryBuilderOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import ImportantDevicesOutlinedIcon from '@material-ui/icons/ImportantDevicesOutlined';
import DevicesOutlinedIcon from '@material-ui/icons/DevicesOutlined';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import SubMenu from './subMenu';

export const MyMenu = (props, { dense = false }) => {
    const resources = useSelector(getResources)
    const { permissions, loading: permissionsLoading}  = usePermissions()
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme);
    const [state, setState] = useState({
        menuMyPunches: true,
        menuManager: true,
        //menuAdmin: false,
    });

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    const { identity, loading: identityLoading } = useGetIdentity();
    if (identityLoading || permissionsLoading) return (null);
    return (
        <Menu {...props}>
            <DashboardMenuItem />
            <MenuItemLink to={{
                    pathname: '/employees/' + identity.id + '/show',
                }} primaryText="My Profile" leftIcon={<PersonOutlineOutlinedIcon />}/>
            <MenuItemLink to={{
                    pathname: '/myAssets',
                }} primaryText="My Assets" leftIcon={<DevicesOutlinedIcon />}/>
            <SubMenu
                handleToggle={() => handleToggle('menuMyPunches')}
                isOpen={state.menuMyPunches}
                name="My Punches"
                icon={<NavigateNextOutlinedIcon />}
                dense={dense}
            >
                <MenuItemLink to={{
                    pathname: '/myPunches',
                }} primaryText="List" leftIcon={<QueryBuilderOutlinedIcon />}/>
                <MenuItemLink to={{
                    pathname: '/myPunchesByMonth',
                }} primaryText="By Month" leftIcon={<DateRangeIcon />}/>
            </SubMenu>

            {permissions.includes('prambanan_manager') ? <SubMenu
                handleToggle={() => handleToggle('menuManager')}
                isOpen={state.menuManager}
                name="Manager"
                icon={<NavigateNextOutlinedIcon />}
                dense={dense}
            >
                <MenuItemLink to="/assets" primaryText="Assets" leftIcon={<DevicesOutlinedIcon />}/>
                <MenuItemLink to="/assetModels" primaryText="AssetModels" leftIcon={<ImportantDevicesOutlinedIcon />}/>
                <MenuItemLink to="/locations" primaryText="Locations" leftIcon={<LocationOnOutlinedIcon />}/>
                <MenuItemLink to="/employees" primaryText="Employees" leftIcon={<PeopleAltOutlinedIcon />}/>
                <MenuItemLink to="/punches" primaryText="Punches" leftIcon={<QueryBuilderOutlinedIcon />}/>
                <MenuItemLink to="/punchesByMonth" primaryText="Punches By Month" leftIcon={<DateRangeIcon />}/>
                <MenuItemLink to="/calendars" primaryText="Calendars" leftIcon={<TodayOutlinedIcon />}/>
            </SubMenu> : null}
            {/*<SubMenu
                handleToggle={() => handleToggle('menuAdmin')}
                isOpen={state.menuAdmin}
                name="Admin"
                icon={<NavigateNextOutlinedIcon />}
                dense={dense}
            >
                {resources.map(resource => (
                    <MenuItemLink
                        key={resource.name}
                        to={`/${resource.name}`}
                        primaryText={
                            (resource.options && resource.options.label) ||
                            resource.name
                        }
                        leftIcon={
                            resource.icon ? <resource.icon /> : <DefaultIcon />
                        }
                        onClick={props.onMenuClick}
                        sidebarIsOpen={open}
                    />
                ))}
                </SubMenu>*/}
        </Menu>
    )
}