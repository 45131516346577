import * as React from "react";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import PunchNow from "../components/punches/PunchNow";
export default () => (
    <Card>
        <Title title="Prambanan IT Services" />
        <PunchNow/>
    </Card>
);