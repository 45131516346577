import * as React from 'react';
import {
    Identifier,
    Datagrid,
    DateField,
    TextField,
    DatagridProps,
    FunctionField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import commonRowStyle from '../common/commonRowStyle';


const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
    comment: {
        maxWidth: '18em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    imageCell: {
        'padding-top': '0px',
        'padding-right': '24px',
        'padding-bottom': '0px',
        'padding-left': '16px',
        //'text-align': 'center',
    },
});

const AssetModelListDesktop = ({ selectedRow, ...props }) => {
    const classes = useListStyles();
    return (
        <Datagrid
            rowClick="edit"
            // @ts-ignore
            rowStyle={commonRowStyle(selectedRow)}
            classes={{
                headerRow: classes.headerRow,
                headerCell: classes.headerCell,
                rowCell: classes.rowCell,
            }}
            optimized
        >
            <TextField source="id" />
            <TextField source="type" />
            <FunctionField  cellClassName={classes.imageCell} label="Image" render={record => 
                <img style={{height: '25px', 'verticalAlign': 'middle'}} 
                    src={`${process.env.REACT_APP_API_URL}/assetModels/${record.id}/image`} 
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.remove();
                    }} />
            }/>
            
            <TextField source="brand" />
            <TextField source="model" />
        </Datagrid>
    );
};

export default AssetModelListDesktop;