import AssetModelsPage from "./AssetModelsPage";
/*
export default {
    list: AssetModelsPage,
    create: AssetModelsPage
};
*/

const resources = (permissions) => (
    {
        list: AssetModelsPage,
        create: (permissions.includes('prambanan_manager') ? AssetModelsPage : undefined),
    }
)

export default resources